import React from "react"
import PeopleModal from "../../components/People/PeopleModal"

function PeoplePage() {
  const person = {
    image: {
      dimensions: {
        width: 580,
        height: 631,
      },
      alt: "michael e. kassan",
      copyright: null,
      url:
        "https://images.prismic.io/medialink/667d6e58-07be-43d7-acd3-0d0650c77223_mek.png?auto=compress,format",
    },
    name: [
      {
        type: "heading1",
        text: "Michael E. Kassan",
        spans: [],
      },
    ],
    position: [
      {
        type: "paragraph",
        text: "Founder, Chairman and CEO",
        spans: [],
      },
    ],
    linkedin: {
      link_type: "Web",
      url: "https://www.linkedin.com/in/michaelkassan/",
    },
    twitter_url: {
      link_type: "Web",
      url: "https://twitter.com/mkassan?lang=en",
    },
    experience: [
      {
        type: "paragraph",
        text: "20",
        spans: [],
      },
    ],
    sport: [
      {
        type: "paragraph",
        text: "Rugby",
        spans: [],
      },
    ],
    education: [
      {
        type: "paragraph",
        text: "University of California",
        spans: [],
      },
      {
        type: "paragraph",
        text: "Los Angeles",
        spans: [],
      },
    ],
    bio: [
      {
        type: "paragraph",
        text:
          "Michael E. Kassan is the founder, Chairman and CEO of MediaLink, a leading strategic advisory firm. Described as the “ultimate power broker,” he is a trusted advisor on speed dial with every major executive in the media, marketing, entertainment and technology C-Suite. They seek his insights and instruction for solving their most complex business challenges. Kassan and his global team of more than 125 specialists provide counsel for navigating the age of digital disruption.",
        spans: [],
      },
    ],
    quote: [],
    specialty: [
      {
        specialty_area: [
          {
            type: "heading6",
            text: "Agency Optimization",
            spans: [],
          },
        ],
      },
      {
        specialty_area: [
          {
            type: "heading6",
            text: "Brand Transformation",
            spans: [],
          },
        ],
      },
      {
        specialty_area: [
          {
            type: "heading6",
            text: "Data & Tech",
            spans: [],
          },
        ],
      },
    ],
    goods: [
      {
        media: {
          id: "YJsbdxIAACUAqsKS",
          type: "media",
          tags: ["Good Read"],
          slug:
            "as-digital-ad-landscape-crowds-and-pandemic-looms-dtc-storage-brand-looks-to-in-person-experiential-marketing",
          lang: "en-us",
          link_type: "Document",
          isBroken: false,
        },
      },
      {
        media: {
          id: "YJsbRhIAACYAqsH3",
          type: "media",
          tags: [],
          slug:
            "meet-13-top-consulting-firms-that-will-help-decide-which-ad-agencies-will-win-the-business",
          lang: "en-us",
          link_type: "Document",
          isBroken: false,
        },
      },
    ],
  }
  return <PeopleModal person={person} />
}

export default PeoplePage
